import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import popupImage from '../assets/images/popup.jpeg';

const Ads = ({ 
  isVisible, 
  onClose, 
  onButtonClick, 
  showButton = true,
}) => {
  const [visible, setVisible] = useState(isVisible);
  const [showCloseButton, setShowCloseButton] = useState(showButton);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (visible && !showCloseButton) {
      setVisible(false);
      if (onClose) onClose();
    }
  }, [visible, showCloseButton, onClose]);

  const handleClose = () => {
    setVisible(false);
    if (onClose) onClose();
  };

  const handleButtonClick = () => {
    setShowCloseButton(false);
    if (onButtonClick) onButtonClick();
  };

  if (!visible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-content">
          <button className="popup-close" onClick={handleClose}>×</button>
          
          <div className="popup-header">
            <h4>Welcome to ICAI Gandhinagar</h4>
            <p>Stay updated with our latest events and announcements.</p>
          </div>
          
          <div className="popup-body">
            <div className="popup-image-container">
              <img 
                src={popupImage}
                alt="Announcement" 
                className="popup-image"
              />
            </div>
          </div>
          
          {showCloseButton && (
            <div className="popup-footer">
              <Button 
                className="popup-button" 
                onClick={handleButtonClick}
              >
                Continue to Site
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ads;