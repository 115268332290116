import React, { useEffect, useRef, useState } from 'react'
// import PropTypes from 'prop-types'
import { Button, Container, Modal, Nav, Navbar } from 'react-bootstrap'
import icaiGandhinagar from '../assets/images/icai-gandhinagar.svg'
import CustomToggleIcon from '../assets/images/navbar-toggle.svg'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useMyContext } from '../context/context'
import { Logout } from '../api/auth'
import Loader from './Loader/Loader'
import { useMutation } from '@tanstack/react-query'
import AlertComponent from './Alert'
import Ads from './Ads'

function Header(props) {
  const { MemberDetails, StudentDetails, refetchMemberProfile, refetchStudentProfile } = props
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { dispatch } = useMyContext()
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false)
  const [message, setMessage] = useState('')
  const [alert, setAlert] = useState(false)
  const [success, setSuccess] = useState(false)
  const MemberToken = localStorage.getItem('MemberToken')
  const StudentToken = localStorage.getItem('StudentToken')
  const userType = MemberToken ? 'M' : StudentToken ? 'S' : ''
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isPopupButtonVisible, setIsPopupButtonVisible] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const navRef = useRef(null)
  
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleToggle = () => setExpanded(!expanded)

  useEffect(() => {
    const popupShown = sessionStorage.getItem('popupShown')
    if ((popupShown === null || popupShown !== 'true') && pathname === '/') {
      setIsPopupVisible(true)
      sessionStorage.setItem('popupShown', 'true')
    }
  }, [pathname])

  const handlePopupClose = () => {
    setIsPopupVisible(false)
  }

  const handlePopupButtonClick = () => {
    setIsPopupButtonVisible(false)
  }

  useEffect(() => {
    window.addEventListener('unauthorized', function () {
      navigate('/')
      setMessage('Authentication failed')
      setAlert(true)
      setSuccess(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (MemberToken) {
      refetchMemberProfile('M')
      dispatch({ type: 'MEMBER_TOKEN', payload: MemberToken })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MemberToken])

  useEffect(() => {
    if (StudentToken) {
      refetchStudentProfile('S')
      dispatch({ type: 'STUDENT_TOKEN', payload: StudentToken })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StudentToken])

  const { mutate: logout, isPending } = useMutation({
    mutationFn: Logout,
    onSuccess: () => {
      localStorage.removeItem('MemberToken')
      localStorage.removeItem('StudentToken')
      dispatch({ type: 'MEMBER_TOKEN', payload: '' })
      dispatch({ type: 'STUDENT_TOKEN', payload: '' })
      navigate('/')
    },
    onError: (error) => {
      setMessage(error?.response?.data?.message)
      setAlert(true)
      setSuccess(false)
    }
  })

  const handleLogout = () => {
    if (MemberToken) {
      logout({ sEmail: MemberDetails?.sEmail, sUserType: userType })
    } else if (StudentToken) {
      logout({ sEmail: StudentDetails?.sEmail, sUserType: userType })
    }
    setIsOpenLogoutModal(false)
  }

  const handleClose = () => setIsOpenLogoutModal(false)

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false)
      }, 3000)
    }
  }, [alert])

  return (
    <>
    {isPending && <Loader />}
    {(message && alert) && <AlertComponent success={success} alert={alert} setAlert={setAlert} message={message} />}
    
    <Ads 
      isVisible={isPopupVisible}
      onClose={handlePopupClose}
      onButtonClick={handlePopupButtonClick}
      showButton={isPopupButtonVisible}
    />
    
    <Navbar ref={navRef} expand="lg" expanded={expanded} className='d-flex justify-content-start p-0' data-bs-theme="light" sticky='top' onToggle={handleToggle}>
      <Container>
      <Navbar.Brand>
        <img
          src={icaiGandhinagar}
          className="d-inline-block align-top"
          alt="Gandhinagar branch ICAI"
          onClick={() => navigate('/')}
          style={{ cursor: "pointer" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <img src={CustomToggleIcon} alt="Toggle" />
      </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to='/' exact activeClassName="active" onClick={() => setExpanded(false)}>Home</Nav.Link>
            {/* <Nav.Link as={NavLink} to='https://www.icai.org/overview.shtml' target='_blank' activeClassName="active" onClick={() => setExpanded(false)}>Overview</Nav.Link> */}
            <Nav.Link as={NavLink} to='/managing-committee' activeClassName="active" onClick={() => setExpanded(false)}>Committees</Nav.Link>
            <Nav.Link as={NavLink} to='/events' activeClassName="active" onClick={() => setExpanded(false)}>Events</Nav.Link>
            <Nav.Link as={NavLink} to='/member' disabled={StudentToken} activeClassName="active" onClick={() => setExpanded(false)}>Members</Nav.Link>
            <Nav.Link as={NavLink} to='/student' activeClassName="active" onClick={() => setExpanded(false)}>Students</Nav.Link>
            {!(MemberToken || StudentToken) && <Nav.Link as={NavLink} to='/register' activeClassName="active" onClick={() => setExpanded(false)}>Sign up</Nav.Link>}
            {(MemberToken || StudentToken) && <Nav.Link onClick={() => { handleLogout(); setExpanded(false); }}>Logout</Nav.Link>}
            {/* <Nav.Link as={NavLink} to='/resources' activeClassName="active" onClick={() => setExpanded(false)}>Resources</Nav.Link>
            <Nav.Link as={NavLink} to='/services' activeClassName="active" onClick={() => setExpanded(false)}>Services</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Modal show={isOpenLogoutModal} centered onHide={handleClose}>
      <Modal.Header closeButton className='m-header-no-border'>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to log out?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='logout-no-btn' onClick={handleClose}>No</Button>
        <Button className='logout-yes-btn' onClick={handleLogout}>Yes</Button>
      </Modal.Footer>
    </Modal>
  </>)
}

Header.propTypes = {}

export default Header
